import React from "react";
import styled from "styled-components";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 32px;
  max-width: 1200px;
  width: 90%;
  margin: 0 auto;
  padding: 64px 0;
  font-size: 18px;
`;

const Column = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;
function faq() {
  return (
    <Container>
      <Column>
        <b> What is PDF Reader?</b>
        <div>
          PDF Reader is an all-in-one PDF application that contains a full suite
          of PDF tools. With PDF Reader you will be able to read, create, secure
          and review PDF files. You may download a 15-day free trial of PDF
          Reader to ensure our application meets your needs before purchasing.
        </div>
      </Column>

      <Column>
        <b>May I try PDF Reader before I purchase?</b>
        <div>
          Yes, you may try the full version of PDF Reader free for 15 days. Once
          your trial has expired it will be necessary to purchase an activation
          key to continue using the software. You may also uninstall PDF Reader
          if the product is not what you were looking for.
        </div>
      </Column>

      <Column>
        <b>How do I activate PDF Reader after purchasing?</b>
        <div>
          Once your transaction has been processed a webpage will appear
          containing an activation key for your software. You will be prompted
          to paste this activation key into your software to activate it once it
          has been installed. Simply copy the activation code from the webpage
          and paste it into the activation code box. The activation code will
          also be sent to the email address which you provided during the
          purchase process.
        </div>
      </Column>

      <Column>
        <b>Where can I find my activation key after purchasing?</b>
        <div>
          Once your transaction has been processed, a webpage will appear
          containing an activation code for your software. The activation code
          will also be sent to the email address which you provided during the
          purchase process.
        </div>
      </Column>

      <Column>
        <b>I have lost my license key, where can I retrieve it?</b>
        <div>
          You may find your license key in the email we sent you confirming your
          purchase of PDF Reader. If you no longer have this email please
          contact customer support and we will send you a new email containing
          your license key. Our customer service team typically responds within
          24 hours.
        </div>
      </Column>

      <Column>
        <b>
          Can I install PDF Reader on multiple computers using the same license
          key?
        </b>
        <div>
          No, each license key will only work on a single computer. To install
          PDF Reader on multiple computers it is necessary to purchase multiple
          license keys.
        </div>
      </Column>

      <Column>
        <b>What are the system requirements for PDF Reader?</b>
        <div>
          PDF Reader is compatible with Windows 7, Windows Vista, and Windows
          XP. The minimum system requirements are:
        </div>
        <br />
        <div>- 100% PC compatible</div>

        <div>- Pentium II, 300 mHz</div>
        <div>- 128 Mb RAM</div>
        <div>- 30 MB free disk space</div>
      </Column>

      <Column>
        <b>What kind of support do you offer?</b>
        <div>
          We currently offer customer service support through email only. Our
          qualified customer service representatives typically respond to email
          support requests within 48 hours.
        </div>
      </Column>

      <Column>
        <b>
          Can I obtain a full refund if PDF Reader isn't what I am looking for?
          What is your policy?
        </b>
        <div>
          You may obtain a full refund within the first 30 days of purchasing
          PDF Reader. We have a no-questions-asked policy meaning you may return
          the product for any reason.
        </div>
      </Column>

      <Column>
        <b>
          How will you protect the personal information I enter on your website?
        </b>
        <div>
          Please refer to our privacy policy, cookie policy, and terms of
          service.
        </div>
      </Column>

      <Column>
        <b>Is PDF Reader available in multiple languages?</b>
        <div>
          PDF Reader is currently available in English, French, German, and
          Spanish. You can change the language within the interface at any time.
        </div>
      </Column>

      <Column>
        <b>Will I receive a physical copy of PDF Reader in the mail?</b>
        <div>
          No, but you will have the option of purchasing a physical copy of PDF
          Reader during the checkout process. To purchase a physical copy of PDF
          Reader, simply check the box marked CD Backup on the second page of
          the checkout process and a disk containing a copy of PDF Reader will
          be delivered to the address you provide us.
        </div>
      </Column>
    </Container>
  );
}

export default faq;
